import { StaticImage } from "gatsby-plugin-image"
import React from "react"

const MclarenImageOne = () => {
    return (
        <div className="h-full w-full">
            <StaticImage
                src={"/case-study/mclaren/Mclaren - slide - 2.png"}
                alt="Mclaren mockup"
                loading="eager"
                objectFit="cover"
                className="h-full w-full"
            />
        </div>
    )
}

const MclarenImageTwo = () => {
    return (
        <div className="h-full w-full">
            <StaticImage
                src={"/case-study/mclaren/Mclaren - slide - 3.png"}
                alt="Mclaren mockup"
                loading="eager"
                objectFit="cover"
                className="h-full w-full"
            />
        </div>
    )
}

const MclarenImageThree = () => {
    return (
        <div className="h-full w-full">
            <StaticImage
                src={"/case-study/mclaren/Mclaren - slide - 4.png"}
                alt="Mclaren mockup"
                loading="eager"
                objectFit="cover"
                className="h-full w-full"
            />
        </div>
    )
}

const MclarenImageFour = () => {
    return (
        <div className="h-full w-full">
            <StaticImage
                src={"/case-study/mclaren/Mclaren - slide - 5.png"}
                alt="Mclaren mockup"
                loading="eager"
                objectFit="cover"
                className="h-full w-full"
            />
        </div>
    )
}



export {
    MclarenImageOne,
    MclarenImageTwo,
    MclarenImageThree,
    MclarenImageFour,
}
