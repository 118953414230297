import gsap from "gsap"
import ScrollTrigger from "gsap/dist/ScrollTrigger"
import React, { useEffect, useRef } from "react"
import { StaticImage } from "gatsby-plugin-image"

if (typeof window !== "undefinded") {
    gsap.registerPlugin(ScrollTrigger)
}
function MandalaBanner({ className, children }) {
    const $wrapper = useRef(null)
    const $image = useRef(null)
    useEffect(() => {
        const mm = gsap.matchMedia($wrapper)
        mm.add("(min-width: 1024px)", () => {
            gsap.fromTo(
                $image.current,
                { yPercent: -60 },
                {
                    yPercent: -40,
                    scrollTrigger: {
                        trigger: $wrapper.current,
                        scrub: 1,

                        // markers: true,
                        start: "top bottom-=20%",
                        end: "bottom+=100% top"
                    },
                    duration: 1,
                    ease: "none"
                }
            )
        })

        return () => {
            mm.revert()
        }
    }, [])
    return (
        <div
            className={`relative ${className} h-[50vh] overflow-hidden lg:aspect-[1440/460] lg:h-auto lg:w-full`}
            ref={$wrapper}
        >
            <div
                className={`absolute left-1/2 top-1/2 h-full w-full -translate-y-1/2 -translate-x-1/2 lg:h-auto lg:translate-y-0 lg:tracking-tighter ${className}`}
                ref={$image}
            >
                {children}
            </div>
        </div>
    )
}

export default MandalaBanner
