import gsap from "gsap"
import ScrollTrigger from "gsap/dist/ScrollTrigger"
import React, { useEffect, useRef } from "react"
// import "./testimonial.scss"

if (typeof window !== "undefinded") {
    gsap.registerPlugin(ScrollTrigger)
}

const StaticTestimonial = ({
    title,
    content,
    author,
    className,
    position,
    subTitle
}) => {
    const $wrapper = useRef(null)
    const $title = useRef(null)
    const $content = useRef(null)

    useEffect(() => {
        const mm = gsap.matchMedia($wrapper)

        //  console.log('first', first)
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: $wrapper.current,
                once: true,

                start: "top bottom+=20%"
            }
        })
        mm.add("(min-width: 1024px)", () => {
            ScrollTrigger.refresh()
            tl.from([$title.current, $content.current], {
                y: 40,
                opacity: 0,
                stagger: 0.2,
                duration: 0.8
            })
        })

        return () => {
            mm.revert()
        }
    }, [])

    return (
        <div className={className}>
            <div
                className={`static-testi relative bg-dark-gunmetal py-20 text-white lg:pb-[100px]`}
                ref={$wrapper}
            >
                <img
                    src="/case-study/testimonial-shape-1.svg"
                    className="absolute top-10 left-8"
                    alt=""
                />
                <img
                    src="/case-study/testimonial-shape-2.svg"
                    className="absolute bottom-0 left-0 -translate-x-1/3 translate-y-1/3"
                    alt=""
                />
                <img
                    src="/case-study/testimonial-shape-3.svg"
                    className="absolute right-12 top-12"
                    alt=""
                />
                <img
                    src="/case-study/testimonial-shape-4.svg"
                    className="absolute bottom-0 right-0 translate-y-1/2 translate-x-1/3"
                    alt=""
                />
                <div class="container grid gap-12 lg:grid-cols-12 lg:gap-x-[30px]">
                    <h2
                        className=" text-center text-3xl font-semibold lg:col-span-12 lg:text-[40px] lg:leading-snug"
                        ref={$title}
                    >
                        {title}
                        {subTitle && (
                            <p className="mt-3 text-2xl font-semibold text-white">
                                {subTitle}
                            </p>
                        )}
                    </h2>

                    <div
                        className="relative mx-5 lg:col-span-8 lg:col-start-3"
                        ref={$content}
                    >
                        <div class="absolute top-3 left-3 z-0 h-full w-full rounded-[10px] bg-primary-color lg:top-5 lg:left-5"></div>
                        <div
                            className="inner relative z-10 flex max-w-[730px] flex-col rounded-[10px] bg-white
                    p-6 pt-[84px] text-black after:bg-primary-color lg:max-w-none"
                        >
                            <svg
                                className="absolute top-6 left-6"
                                xmlns="http://www.w3.org/2000/svg"
                                width="44"
                                height="44"
                                viewBox="0 0 44 44"
                                fill="none"
                            >
                                <path
                                    d="M33.4503 22.3228C37.5622 22.3228 40.3332 25.2348 40.3332 29.5495C40.3332 33.4322 37.1484 36.6668 32.8348 36.6668C28.1101 36.6668 24.6189 32.7842 24.6189 26.8535C24.6189 13.3735 34.2727 7.9815 40.3332 7.3335V13.2642C36.2239 14.0188 31.6039 18.2242 31.397 22.8642C31.6039 22.7575 32.4236 22.3228 33.4503 22.3228ZM12.5006 22.3228C16.6072 22.3228 19.3808 25.2348 19.3808 29.5495C19.3808 33.4322 16.196 36.6668 11.8825 36.6668C7.15769 36.6668 3.6665 32.7842 3.6665 26.8535C3.6665 13.3735 13.3203 7.9815 19.3808 7.3335V13.2642C15.2715 14.0188 10.6515 18.2242 10.4446 22.8642C10.6515 22.7575 11.4713 22.3228 12.5006 22.3228Z"
                                    fill="#83A3F9"
                                />
                            </svg>
                            <p className="mb-6 lg:max-w-[88%]">{content}</p>
                            <hr className="mb-2 h-[2px] w-10 border-t-2 border-solid border-primary-color" />
                            <h4 className="mb-2 text-base font-semibold  text-primary-color">
                                {author}
                            </h4>
                            <p class="text-sm leading-[1.2]">{position}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StaticTestimonial
