import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import gsap from "gsap"
import ScrollTrigger from "gsap/dist/ScrollTrigger"
import React, { useEffect, useRef } from "react"
import { caseStudies } from "."
import ShowCase from "../../components/CaseStudy/CaseStudyShowCase"
import MandalaBanner from "../../components/CaseStudy/MandalaBanner"
import {
    MclarenImageFour,
    MclarenImageOne,
    MclarenImageThree,
    MclarenImageTwo
} from "../../components/CaseStudy/MclarenImage"
import Overview from "../../components/CaseStudy/Overview"
import ContentImage from "../../components/ContentImage/ContentImage"
import Newsletter from "../../components/Form/Newsletter"
import Hello from "../../components/Hello/Hello"
import HeroTwo from "../../components/Hero/HeroTwo"
import Layout from "../../components/layout"
import { SEO } from "../../components/seo"
import StaticTestimonial from "../../components/Testimonial/StaticTesimonial"
import TitleContent from "../../components/TitleContent/TitleContent"
import "./case-studies.scss"

if (typeof window !== "undefined") {
    gsap.registerPlugin(ScrollTrigger)
}

const techs = [
    { src: "/case-study/prismic.svg" },
    { src: "/case-study/jam.svg" },
    { src: "/case-study/next.svg" },
    { src: "/case-study/swiper.svg" },
]

const sliderImages = [
    {
        src: "/case-study/mclaren/Mclaren - slide - 2.png",
        text: "We solve economic, policy and regulatory problems",
        component: MclarenImageOne
    },
    {
        src: "/case-study/mclaren/Mclaren - slide - 3.png",
        text: "We use novel data and strategy for the changing world",
        component: MclarenImageTwo
    },
    {
        src: "/case-study/mclaren/Mclaren - slide - 4.png",
        text: "AI Human Capital Investment Index",
        component: MclarenImageThree
    },
    {
        src: "/case-study/mclaren/Mclaren - slide - 5.png",
        text: "Proven strategy consulting methods",
        component: MclarenImageFour
    },
]

const overview = {
    overview:
        "McLaren Group Construction is a leading name in the construction industry. We worked together to rebuild McLaren Group website, utilising Prismic CMS as a Headless CMS and NextJS on the JAMstack architecture. This case study highlights our journey in delivering a handcrafted, highly performant, and user-friendly website that showcases their construction projects, sectors, and industries to the world.",
    services: ["Prismic", "UI/UX", "SEO", "JAMstack Development"],
    linkUrl: "https://mclarengroup.com/"
}

const lhScores = [
    {
        title: "Performance",
        score: 85
    },
    {
        title: "Accessibility",
        score: 91
    },
    {
        title: "Best Practices",
        score: 92
    },
    {
        title: "SEO",
        score: 100
    }
]

const tlDefaults = {
    ease: "slow.inOut",
    duration: 1.25
}

const MandalaDetail = ({ location }) => {
    const $sliderSection = useRef(null)
    const $pageWrapper = useRef(null)
    const $slider = useRef(null)
    const $progress = useRef(null)
    const $contentImageWrapper = useRef(null)
    const $contentImagePin = useRef(null)

    const currentSlide = useRef(undefined)
    const next = useRef(0)
    const alreadyEntered = useRef(false)

    const heroData = {
        preheader: "McLaren Group",
        title: "Exceptional People, Exceptional Places and an Exceptional Website"
    }
    const context = {
        pageName: "SD | Case studies - McLaren",
        pageUri: location.href
    }

    useEffect(() => {
        const ctx = gsap.context(() => {
            const outers = gsap.utils.toArray(".slider-outer")
            const inners = gsap.utils.toArray(".slider-inner")
            const images = gsap.utils.toArray(".slider-image")

            gsap.set(outers, { yPercent: 100 })
            gsap.set(inners, { yPercent: -100 })
            gsap.set(images, { scale: 1.3, yPercent: 15 })
            gsap.set($progress.current, { scaleX: 0 })

            ScrollTrigger.create({
                trigger: $sliderSection.current,
                start: "top top",
                // markers: true,
                onEnter: () => {
                    if (!alreadyEntered.current) {
                        slideIn()
                    }

                    alreadyEntered.current = true
                }
            })
        })

        return () => {
            ctx.revert()
        }
    }, [])

    useEffect(() => {
        const mm = gsap.matchMedia()

        mm.add("(min-width: 1024px)", () => {
            const contentImages =
                $contentImageWrapper.current.querySelectorAll(".content-image")

            contentImages[0].classList.add("is-active")

            ScrollTrigger.create({
                trigger: $contentImageWrapper.current,
                // pin: $contentImagePin.current,
                // pinSpacer: false,
                start: "top top",
                // end: `+=${contentImages.length * 100}% bottom`,
                // markers: true,
                onLeave: () => {
                    contentImages[0].classList.remove("is-active")
                    contentImages[contentImages.length - 1].classList.add(
                        "is-active"
                    )
                },
                onUpdate: self => {
                    const progress = Number(self.progress.toFixed(3))
                    const total = contentImages.length + 1

                    contentImages.forEach((_, i) => {
                        const oldIndex = i
                        const newIndex = oldIndex + 1

                        if (
                            progress > oldIndex / total &&
                            progress < newIndex / total
                        ) {
                            contentImages[oldIndex].classList.add("is-active")

                            if (self.direction === 1 && oldIndex > 0) {
                                contentImages[oldIndex - 1].classList.remove(
                                    "is-active"
                                )
                            } else if (
                                self.direction === -1 &&
                                newIndex < contentImages.length
                            ) {
                                contentImages[newIndex].classList.remove(
                                    "is-active"
                                )
                            }
                        }
                    })
                }
            })
        })

        return () => {
            mm.revert()
        }
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            if (currentSlide.current !== undefined) {
            }
        }, 4000)

        return () => {
            clearInterval(interval)
        }
    }, [])

    // Slides a section in on scroll down
    function slideIn() {
        const outers = gsap.utils.toArray(".slider-outer")
        const inners = gsap.utils.toArray(".slider-inner")
        const images = gsap.utils.toArray(".slider-image")
        const texts = gsap.utils.toArray(".slider-text")

        // The first time this function runs, currentSlide is undefined
        const tl = gsap.timeline({
            paused: true,
            defaults: tlDefaults,
            onComplete: () => {
                currentSlide.current = next.current
                next.current = currentSlide.current + 1
                if (next.current > sliderImages.length - 1) {
                    currentSlide.current = sliderImages.length - 1
                    next.current = 0
                }

                const timeout = setTimeout(() => {
                    slideIn()
                }, 2000)
            }
        })

        tl.to(outers[next.current], { yPercent: 0 }, 0)
            .to(inners[next.current], { yPercent: 0 }, 0)
            .to(images[next?.current], { yPercent: 0, scale: 1 }, 0)
            .to(
                $progress.current,
                { scaleX: (next.current + 1) / sliderImages.length },
                0
            )
            .fromTo(
                texts[next?.current],
                { y: 40, opacity: 0 },
                { y: 0, opacity: 1 },
                0
            )

        if (currentSlide?.current !== undefined) {
            tl.to(outers[currentSlide.current], { yPercent: -100 }, 0)
                .to(inners[currentSlide.current], { yPercent: 100 }, 0)
                .to(
                    images[currentSlide.current],
                    { scale: 1.2, yPercent: -15 },
                    0
                )
                .to(
                    texts[currentSlide?.current],
                    { y: -40, opacity: 0, duration: 0.8 },
                    0
                )

            tl.add(
                gsap
                    .timeline()
                    .set(outers[currentSlide?.current], {
                        yPercent: 100
                    })
                    .set(inners[currentSlide?.current], {
                        yPercent: -100
                    })
                    .set(images[currentSlide?.current], {
                        yPercent: 15,
                        scale: 1.3
                    })
            )
        }

        tl.play(0)
    }

    return (
        <div ref={$pageWrapper}>
            <Layout context={context}>
                <HeroTwo props={heroData} />
                {/* Overview */}
                <Overview {...overview} />
                {/* banner */}
                <MandalaBanner className={"mt-20 lg:mt-24 hidden lg:block"}>
                    <StaticImage
                        src={"../../../static/case-study/mclaren/Mclaren - Top banner.png"}
                        alt="mandala mockup"
                        objectFit="cover"
                        className="h-full w-full"
                    />
                </MandalaBanner>
                <MandalaBanner className={"mt-20 lg:mt-24 lg:hidden"}>
                    <StaticImage
                        src={"../../../static/case-study/mclaren/Mclaren-2 col - 2.png"}
                        alt="mandala mockup"
                        objectFit="contain"
                        className="h-full w-full"
                    />
                </MandalaBanner>
                <TitleContent
                    title="Revving Up McLaren Group’s Digital Engine with Advanced Technologies"
                    className="py-14 lg:py-24"
                >
                    <p className="mb-4">
                        We dove headfirst into the world of JAMstack architecture, recognising its potential for boosting website speed, enhancing security, and improving SEO. By utilising Next.js for the frontend, we ensured that McLarenGroup’s website wasn’t just about speed; it was also about delivering a smooth, interactive user experience.
                    </p>
                    <p>
                        Prismic CMS with Slice Machine was our choice for content management, enabling McLarenGroup’s admin and editors to build and edit web pages effortlessly. This flexibility in content management mirrors the precision and adaptability found in McLarenGroup’s own ethos.
                    </p>

                    <h3 className="mt-6 text-lg font-semibold">
                        Technologies used:
                    </h3>
                    <div class="mt-6 grid grid-cols-2 gap-4 md:gap-4 lg:grid-cols-3 lg:gap-[30px]">
                        {techs.map(({ src }, index) => {
                            return (
                                <div class="group grid aspect-[160/78] place-items-center border py-2 px-6 transition-all duration-300 hover:shadow-light_shadow">
                                    <img
                                        src={src}
                                        className="h-full w-full max-w-full object-contain grayscale transition-all duration-300 group-hover:grayscale-0"
                                        alt="logos"
                                    />
                                </div>
                            )
                        })}
                    </div>
                </TitleContent>
                {/* developing */}
                <Hello
                    link="https://saigon.digital/blog/advancing-web-security-jamstack-versus-traditional-website-development"
                    title="With Google Lighthouse Scores to boast..."
                    lhScores={lhScores}
                >
                    <p className="mb-4">
                        Lighthouse is an open-source, automated tool for
                        improving the performance, quality, and correctness of
                        your web apps.
                    </p>
                    <p>
                        This is the real point of Google, you can check it here:{" "}
                        <a
                            href="/mclarengroup.com-report.html"
                            target="_blank"
                            download
                            className="font-semibold text-primary-color underline"
                        >
                            Google Lighthouse Report
                        </a>
                    </p>
                </Hello>

                <div className="h-screen" ref={$sliderSection}>
                    <div className="flex min-h-screen flex-col pt-14 lg:pt-24">
                        <TitleContent
                            title="The Challenge"
                            className={"mb-10 lg:mb-16"}
                        >
                            <p className="mb-4">
                                McLaren Group needed a website that not only showcased their expertise in construction but also delivered swift and efficient performance to serve a global audience. An intuitive, user-friendly design was key to effectively engaging and informing visitors, ensuring a seamless online experience. Additionally, they required a flexible content management system (CMS), allowing for easy updates and maintenance to accurately reflect their vast and evolving portfolio, thus keeping their digital presence as dynamic and robust as their projects.
                            </p>

                        </TitleContent>

                    <MandalaBanner className={"hidden lg:block"}>
                    <StaticImage
                    src={"../../../static/case-study/mclaren/Mclaren - Result.png"}
                    alt="Mclaren mockup"
                    objectFit="cover"
                    className="h-full w-full"
                    />
                </MandalaBanner>
                <MandalaBanner className={"lg:hidden"}>
                    <StaticImage
                        src={"../../../static/case-study/mclaren/Mclaren - Result_mobile.png"}
                        alt="Mclaren mockup"
                        objectFit="contain"
                        className="h-full w-full"
                    />
                </MandalaBanner>
                    </div>
                </div>

                {/* approach */}
                {/* Solution */}
                <TitleContent className="mb-10 lg:mb-16 mt-[8%]" title="The Solutions">
                    <h3 className="mb-4 text-2xl font-semibold">Optimised Web Architecture <br className="hidden xl:block"/> and CMS Integration</h3>
                    <p className="mb-6">
                    Saigon Digital opted for a JAMstack architecture, leveraging NextJS to achieve optimal performance. This strategic choice allowed us to pre-render web pages, significantly reducing loading times and guaranteeing a smooth online experience for users. Integrating Prismic CMS was a crucial aspect of our solution, providing McLaren Group with an effortless content management system. This headless CMS solution enabled us to develop a customised content structure that perfectly aligned with their unique requirements.
                    </p>
                    <h3 className="mb-4 text-2xl font-semibold">Bespoke Design and User-Centric Experience</h3>
                    <p>
                    Our team crafted a visually striking design that encapsulated McLaren's excellence in construction. The focus on user-centric design principles was pivotal, ensuring that each visitor to the site enjoyed a seamless and intuitive experience. We also developed a specialised Projects section, dedicated to showcasing the diverse range of McLaren Group projects. This section highlighted each project in detail, supported by engaging visuals that captured the essence of their work.
                    </p>
                </TitleContent>
                {/* banner 2 */}
                <div className="container mb-10 grid gap-y-10 gap-x-[30px] md:grid-cols-2 lg:mb-16 lg:gap-x-24">
                    <div className="aspect-[475/356] w-full overflow-hidden md:ml-5">
                        <div className="h-full w-full object-cover transition-transform duration-1000 hover:scale-105">
                            <StaticImage
                                src="../../../static/case-study/mclaren/Mclaren - 2 col - 1.png"
                                className="h-full w-full"
                                objectFit="cover"
                                alt=""
                            />
                        </div>
                    </div>
                    <div className="aspect-[475/356] w-full overflow-hidden md:mr-5">
                        <div className="h-full w-full object-cover transition-transform duration-1000 hover:scale-105">
                            <StaticImage
                                src="../../../static/case-study/mclaren/Mclaren-2 col - 2.png"
                                className="h-full w-full"
                                objectFit="cover"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
                <Newsletter context={context} />

                <TitleContent className="mb-10 lg:mb-16" title="Outcomes">
                    <p>
                    The website featured distinct sections highlighting McLaren’s involvement across various construction sectors and industries, offering visitors a thorough understanding of their wide-ranging expertise. To ensure the website stood up to the demands of a global audience, we employed meticulous performance optimisation techniques. These efforts resulted in a website that not only loaded quickly but also offered effortless navigation, thereby enhancing the overall user experience on a global scale.
                    </p>
                </TitleContent>

                <div ref={$contentImageWrapper}>
                    <div
                        className="relative lg:sticky lg:top-[20vh] lg:h-screen"
                        ref={$contentImagePin}
                    >
                        <ContentImage
                            className="lg:opacity-100"
                            img={"/case-study/mclaren/Mclaren-Outcome - 4.png"}
                        >
                            <h2 className="mb-4 text-2xl font-semibold">
                                Enhanced User Experience Through Intuitive Design
                            </h2>

                            <p>
                            The revamp of the McLaren Group website brought about a significant enhancement in the user experience, marked by intuitive design and superior performance. This transformation ensures a seamless, engaging journey for visitors, reflecting McLaren's commitment to detail and excellence. The user-centric design approach has elevated the ease of navigation, making the website both visually compelling and straightforward to use.
                            </p>
                            
                        </ContentImage>
                        <ContentImage
                            img={"/case-study/mclaren/Mclaren-Outcome - 5.png"}
                        >
                            <h2 className="mb-4 text-2xl font-semibold">
                                Showcasing Expertise with Seamless Content Management
                            </h2>

                            <p>
                            The new sections dedicated to Projects, Sectors, and Industries effectively highlight McLaren Group diverse range of expertise, thereby widening audience engagement. Integration of Prismic CMS has revolutionised content management, empowering the McLaren team to easily update and maintain the website. This keeps the site’s content current and in sync with their latest projects and achievements, maintaining a dynamic and forward-looking digital presence.
                            </p>
                        </ContentImage>
                        <ContentImage
                            img={"/case-study/mclaren/Mclaren-Outcome - 6.png"}
                        >
                            <p>
                            By integrating these technologies, we didn't just revamp a website; we turbocharged it. The focus was on not just meeting but exceeding performance benchmarks. This resulted in a significant increase in the overall Lighthouse score and Core Web Vitals performance, indicators of a site that loads faster, is more interactive and offers greater stability.
                            </p>
                        </ContentImage>
                    </div>
                    <div className="lg:h-[400vh]"></div>
                </div>

                <TitleContent
                    className="mb-10 lg:mb-16 lg:-mt-[30vh]"
                    title="Conclusion"
                >
                   
                    <p>
                    Saigon Digital's partnership with McLaren Group resulted in the successful redevelopment of their website. Our JAMstack architecture, Prismic CMS integration, and bespoke design approach led to a highly performant, user-friendly, and engaging website. We are proud to have delivered a digital platform that reflects the construction excellence synonymous with McLaren Group Construction. This case study really highlights how we're all about building digital solutions that not only hit the mark but go the extra mile, especially when it comes to the construction industry.
                    </p>
                </TitleContent>
                <MandalaBanner className={"hidden lg:block !h-full"}>
                    <StaticImage
                            src={"../../../static/case-study/mclaren/Mclaren - Result.png"}
                            alt="Mclaren mockup"
                            objectFit="cover"
                            className="h-full w-full"
                    />
                </MandalaBanner>
                <MandalaBanner className={"lg:hidden"}>
                    <StaticImage
                        src={"../../../static/case-study/mclaren/Mclaren - Result_mobile.png"}
                        alt="Mclaren mockup"
                        objectFit="contain"
                        className="h-full w-full"
                    />
                </MandalaBanner>
                <StaticTestimonial
                    className={"-mt-[2px]"}
                    title={"Thoughts from McLaren"}
                    content={
                        <>
                        <p>
                        “It has been a pleasure to work with Saigon Digital over the past year, their contributions have been truly exceptional. The team is very responsive and so efficient in delivering website requests, and despite the time difference, the turnaround for everything was so quick. They take the time to understand your business needs and suggest digital solutions that will help you get closer to your goals while offloading some of the pressures that digital marketing brings.
                        </p>
                        <p className="my-4">The team has been great at collaborating and sharing their expertise with our internal team, providing us with a simple breakdown of the key elements to look out for when measuring our website performance and ensuring we had the tools to measure success. </p>
                        <p>I’m sure this is only the beginning of our working relationship with Saigon Digital, but I’d like to send a special thank you to the team: Forrest, Nick, and Jonas, you have been an absolute pleasure to work with. I have no doubt that you will continue to excel in your future endeavours.”</p>
                        </>
                        
                    }
                    author={"Layla Reynolds"}
                    position="Communications Business Partner at McLaren Group"
                />
                <ShowCase data={caseStudies} />
            </Layout>
        </div>
    )
}

export default MandalaDetail

export const Head = () => (
    <SEO
        title={"McLaren Group | Saigon Digital"}
        description={
            "We worked together to rebuild McLaren Group Construction's website, utilising Prismic CMS as a Headless CMS and NextJS on the JAMstack architecture"
        }
        keywords={
            "web design saigon, web development saigon, jamstack development, jamstack web development saigon"
        }
        image={"/case-study/mclaren/Open_Graph.png"}
    />
)
